/**
 * File responsible for the content when clicking `Add comment` in management pages.
 */

import { Button, Checkbox, Col, Form, Input, Modal, Row } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import { filter, get, isEmpty, map, over } from 'lodash';
import React, { lazy, Suspense, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { getPopoverContainer, getTranslatedText } from '../../../utils/commonFunctions';
import { formFieldNames } from '../../../constants/announcementsSortAndFilters';
import { confirmModalCancelText, confirmModalOkText } from '../../../config/config';
import { CompanyUserRole } from '../../../store/companies/types';
import { ApplicationState } from '../../../store';
import { createCreditAsOverpaymentTasksRequest } from '../../../store/payments/actions';
import { DynamicObject } from '../../../utils/commonInterfaces';
import { getCreditsByCreditCodesRequestAction } from '../../../store/credits/actions';
import { Credit } from '../../../store/credits/types';

const ModalWithSpinner = lazy(
    () => import('../../common/ModalWithSpinner')
);

const { Item: FormItem } = Form;

interface IProps {
    readonly containerRef?: any;
    readonly visible: boolean;
    readonly closePanel?: (
        refetchList?: boolean,
        refetchConversationList?: boolean
    ) => void;
    readonly form?: any;
    readonly filterPayload?: {};
    readonly dispatchAction?: (payload: any) => void;
    readonly getSelectedTasksValues: () => {
        allExcept: boolean;
        keysToUse: string[];
        filterObject: any;
        sortBy: string,
        sortAscending: boolean,
        recordLimit?: number,
    };
    readonly selectedTaskIds: string[];
}
const { confirm } = Modal;

const TaskActionManuallyRenameOverpaymentsPanel: React.FC<IProps> = ({
    visible,
    closePanel,
    form,
    filterPayload,
    getSelectedTasksValues,
    dispatchAction,
    selectedTaskIds
}: IProps) => {
    const dispatch = useDispatch();
    const [submitLoading, setSubmitLoading] = useState<boolean>(false);
    const { getFieldDecorator, resetFields } = form;
    const [submitOverpaymentLoading, setSubmitOverpaymentLoading] = useState<boolean>(false); 
    const [overpaymentCode, setOverpaymentCode] = useState<string | null>('');

    const selectedUserCompany: CompanyUserRole = useSelector(
        (state: ApplicationState) => state.companies.selectedUserCompany
    );

    const { keysToUse, allExcept, filterObject, sortBy, sortAscending, recordLimit } = getSelectedTasksValues();

    /**
     * Function called when `Cancel` button is clicked inside Add comment panel.
     */
    const handleClosePanel = () => {
        if (closePanel) closePanel();
    };

    /**
     * Function that listens if panel is closed.
     * If closed, the form fields and values will reset.
     */
    const listenForClosingPanel = () => {
        if (!visible) {
            resetFields();
        }
    };

    useEffect(listenForClosingPanel, [visible]);

     /**
         * Function called when clicking the `Action item` button
         * Create credit as overpayment
         */
         const handleCreateCreditAsOverpaymentForm = () => {
            confirm({
                className: 'modal-swapped-buttons',
                title: getTranslatedText('Warning'),
                content: (
                    <div>
                       {/* {getTranslatedText(`This action will create a credit in the IODM System${selectedTaskIds.length > 1 ? '.' : ` for the remaining amount of [amount].`}`).replace("[amount]", getAmountToAllocate().toString())} */}
                        <div className="mt-8">
                           {getTranslatedText("Are you sure that you wish to create a credit?")}
                        </div>
                    </div>
                ),
                okText: getTranslatedText(confirmModalOkText),
                cancelText: getTranslatedText(confirmModalCancelText),
                onOk() {
                    CheckOverpaymentDuplicates();
                }
            });
        };

     const CheckOverpaymentDuplicates = () => {
        setSubmitOverpaymentLoading(true);
        setSubmitLoading(true);
        let payload: DynamicObject = {
                        CreditCodes: overpaymentCode
                    };
        
        dispatch(
            getCreditsByCreditCodesRequestAction(
                payload, 
                (newCredits: Credit[]) => {
                    if(newCredits.length == 1){
                        setSubmitOverpaymentLoading(false);
                        setSubmitLoading(false);
                        let errorMessageContent: any = getTranslatedText(`The following overpayment ${overpaymentCode} already existed.`);

                        Modal.error({
                            title: getTranslatedText('Error'),
                            content: errorMessageContent,
                        });
                    }
                    else {
                        CreateCreditAsOverpayment();
                    }
                }
            ));
     };

     const CreateCreditAsOverpayment = () => {
            setSubmitOverpaymentLoading(true);
            setSubmitLoading(true);
            const selectedCompanyId = get(selectedUserCompany, 'Company.CompanyId');
    
            const payload = {
                filter: filterObject,
                taskIds: keysToUse,
                excludeTasks: allExcept,
                sortBy, 
                sortAscending, 
                recordLimit,
                CompanyId: selectedCompanyId,
                OverpaymentCode: overpaymentCode
            };
    
            dispatch(
                createCreditAsOverpaymentTasksRequest({
                    ...payload,
                    callback: createCreditAsOverpaymentModal,
                })
            )
        };
    
        const createCreditAsOverpaymentModal = ({
            IsSuccess,
            Messages,
            RefetchList = true,
        }: {
            IsSuccess: boolean;
            Messages: string[] | undefined;
            RefetchList: boolean;
        }) => {
            setSubmitOverpaymentLoading(false);
            setSubmitLoading(false);
            if (IsSuccess) {
                Modal.success({
                    title: getTranslatedText('Success'),
                    content: getTranslatedText('Credit has been created successfully!'),
                    okText: getTranslatedText(confirmModalOkText),
                    onOk: () => {
                        if (closePanel) closePanel(RefetchList);
                    },
                });
            } else {
                let errorMessageContent: any = getTranslatedText(`Failed to create credit!`);
                if (!isEmpty(Messages)) {
                    errorMessageContent = map(
                        Messages,
                        (error: string, index: number) => (
                            <div key={index}>{error}</div>
                        )
                    );
                }
                Modal.error({
                    title: getTranslatedText('Error'),
                    content: errorMessageContent,
                });
            }   
        }

    const formItemLayout = {
        labelCol: {
            xs: { span: 24 },
            sm: { span: 8 },
        },
        wrapperCol: {
            xs: { span: 24 },
            sm: { span: 16 },
        },
    };

    /**
     * Function responsible for populating the panel content.
     * Form fields.
     */
    const populatePanelContent = () => {
        return (
            <Form className="form-inline-mb-0" labelCol={{ span: 12 }}>
                <Row type="flex" align="middle">
                    <Col span={24}>
                            <FormItem 
                            {...formItemLayout}
                            label={getTranslatedText("Overpayment Code")}
                            >
                                {getFieldDecorator('OverpaymentCode', {
                                    rules: [
                                        {
                                            max: 45,
                                            message: 'Overpayment Code must not exceed 45 characters!',
                                        },
                                    ],

                                })(<Input 
                                    onChange={(e) => setOverpaymentCode(e.target.value)}
                                    maxLength={45} 
                                    placeholder='OP_{Payment Reference}_yyMMHHmmss'
                                    />
                                )}
                            </FormItem>
                        <div style={{ color: 'orange', textAlign:"right", padding:0}}>Leave blank to use payment reference as the default overpayment code</div>
                    </Col>
                </Row>
            </Form>
        );
    };

    return (
        <Row>
            <Col>
                <div>{populatePanelContent()}</div>
                <br />
                <Row>
                    <Col className="ta-right" span={24}>
                        <Button
                            className="mr-8"
                            type="primary"
                            onClick={handleCreateCreditAsOverpaymentForm}
                        >
                            {getTranslatedText("Save")}
                        </Button>
                        <Button onClick={handleClosePanel}>{getTranslatedText("Cancel")}</Button>
                    </Col>
                </Row>
            </Col>
            {submitLoading && (
                <Suspense fallback={null}>
                     <ModalWithSpinner
                        modalTitle={getTranslatedText("Creating credit from overpayment")}
                        modalVisible={submitOverpaymentLoading}
                        displayMessage={getTranslatedText("Please wait while creating credit . . .")}
                    />
                </Suspense>
            )}
        </Row>
    );
};

const TaskActionManuallyRenameOverpaymentsPanelForm = Form.create({
    name: 'add-comment-panel-form',
})(TaskActionManuallyRenameOverpaymentsPanel);

export default withRouter(TaskActionManuallyRenameOverpaymentsPanelForm);
function getFieldValue(Name: any) {
    throw new Error('Function not implemented.');
}

